<template>
  <div class="content">
<!--   地图选址 -->
    <LiefengModal title="地图选址" width="62%" height="calc(100vh - 100px)" @input="canceDown" :value="cancelStatus" class="modal-location">
      <template #contentarea>
        <template v-if="cancelStatus" style="width: 100%;height: 100%;">
          <div >
            <div class="location">
              <Input
                placeholder="请输入搜索的地址"
                v-model="input1"
                style="width: 300px;margin-left: 20px;"
            />
            <Button type="info" style="margin-left: 10px" @click="saveFn">搜索</Button>
            <div class="location-div">当前点击坐标为：<span class="position">{{ latGet }}</span></div>
            </div>

            <div id="container" style="width:100%;height:500px;margin: 10px auto"></div>
            <!-- <div id="info">当前点击坐标为：<span id="position">{{ latGet }}</span></div> -->
          </div>
        </template>
      </template>
      <template #toolsbar>
        <Button type="info" style="margin-right: 10px" @click="canceDown(false)">取消</Button>
        <Button type="primary" @click="cancelSave">确定</Button>
      </template>
    </LiefengModal>
    <div class="header-wrapper">
      <Tooltip placement="left-start" class="tooltip" v-if="!echoToggle">
        <div slot="content" style="width: 200px;white-space: normal">
          由于社区太多，请输入关键字进行刷选
        </div>
        <span>社区</span>
        <Select
            id="selectTxt"
            v-model.trim="type" style="width:200px"
            filterable
            :remote-method="loadingCommunityFn"
            :loading="loadingCommunity"
            :clearable="true"
            placeholder="按社区名称搜索"
        >
          <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
        </Select>
      </Tooltip>
      <!-- <Button type="info" @click="butFn">获取经纬度</Button> -->
    </div>
    <Form :model="formLeft" class="form-wrapper">
      <div>
        <span>地点</span>
        <span>经纬度</span>
        <span>出入</span>
        <span>每户出入限制人数</span>
      </div>
      <div class="form-main" v-for="(item,index) in formLeft.data" :key="index">
        <FormItem style="width: 500px">
          <Input v-model.trim="formLeft.data[index].positionName" maxlength="20"></Input>
        </FormItem>
        <FormItem style="width: 500px">
          <Input v-model="formLeft.data[index].input" :disabled="true"></Input>
        </FormItem>
        <FormItem>
          <Button  type="info" style="margin:0 10px" @click="butFn(formLeft.data[index],index)">获取经纬度</Button>
        </FormItem>
        <template>
          <Select v-model="formLeft.data[index].positionType" style="width:200px">
            <Option :value="item.dictKey" v-for="(item,index) in accessList" :key="index">{{ item.dictValue }}</Option>
          </Select>
        </template>
        <template>
          <Select v-model="formLeft.data[index].paraValue" style="width:200px">
            <Option :value="ren.label" v-for="(ren,index) in option1" :key="index">{{ ren.label }}</Option>
          </Select>
        </template>
      </div>
      <div class="btn-div" v-if="echoToggle == false">
        <Button type="info" @click="addRoad">添加出入口</Button>
      </div>
    </Form>
  </div>
</template>

<script lang="js">
import LiefengModal from "@/components/LiefengModal";
import {TMap} from "@/views/communityaccess/TMap";


var geocoder, map, marker = null;
export default {
  name: "Liefengaccess.vue",
  components: {LiefengModal},
  props: {
    echoToggle: {
      type: Boolean,
    },
    nodeList: {
      type: Array
    },
    allCommunityList: {
      type: Array
    },
    loadingCommunityFn: {
      type: Function
    },
  },
  watch: {},
  data() {
    return {
      //地图选址
      cancelStatus: false,
      input1: "",
      fristForm: {},
      latGet: '',  //保存经纬度的
      markersArray: [],//用来存储标注
      //end
      //新增出入口
      type: "",
      formLeft: {
        data: [
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {}
        ]
      },
      //搜索
      selectObj: {},
      loadingCommunity: false,
      accessList: [],  //字典
      option1: [
        {
          label: '不限'
        },
        // {
        //   label: '1人'
        // },
        // {
        //   label: '2人'
        // },
        // {
        //   label: '3人'
        // },
        // {
        //   label: '4人'
        // },
        // {
        //   label: '5人'
        // },
        // {
        //   label: '6人'
        // },
        // {
        //   label: '7人'
        // },
        // {
        //   label: '8人'
        // },
        // {
        //   label: '9人'
        // },
        // {
        //   label: '10人'
        // }
      ],
      editIndex:0
    }
  },
  async created() {

    //字典
    await this.$get("/old/api/common/sys/findDictByDictType", {
      dictType: 'COMMUNITY_POSITION_TYPE',
    }).then((res) => {
      if (res.code == 200 && res.dataList && res.dataList.length > 0) {
        this.accessList = res.dataList
      } else {
        this.$Message.error({
          background: true,
          content: res.desc
        });
      }
    }).catch(err => {
      this.$Message.error({
        background: true,
        content: "获取数据失败"
      });
    });

  },
  methods: {
    //修改
    save(data) {
      this.formLeft = {
        data: [
          {
            positionName: data.positionName,
            input: data.latitude + ',' + data.longitude,
            positionType: data.positionType,
            paraValue: data.paraList[0].paraValue,
            status: data.status,
            id: data.id,
          }
        ]
      }
    },
    // 添加出入口按钮
    addRoad(){
      this.formLeft.data.push({})
    },
    saveFn() {
      geocoder.getLocation(this.input1);

    },
    //获取经纬度按钮
    butFn(item,index) {
      if(item.positionName == '' || !item.positionName){
        this.$Message.warning({
          content:'请先写填写地点再进行查询',
          background:true
        })
        return
      }
      this.cancelStatus = true;
      this.latGet = ''
      this.editIndex = index
      let locationList = []
      
      this.input1 = item.positionName
      if(item.input!=undefined && item.input !=''){
        locationList = item.input.split(',')
      }else{
        locationList[0] = 23.134101
        locationList[1] = 113.259001
      }
      let that = this;
      this.input1 = item.positionName
      TMap('5MLBZ-M32WI-QIRGI-5C5UD-5M5W3-VFBUK').then(qq => {
        // let name=new qq.maps.POI
        var map = new qq.maps.Map(document.getElementById("container"), {
              //地图的中心地理坐标
              center: new qq.maps.LatLng(locationList[0],locationList[1]), zoom: 11,
            },
        );//
        //绑定单击事件添加参数
        qq.maps.event.addListener(map, 'click', function (event) {
          //创建marker标注
          var marker = new qq.maps.Marker({
            position: new qq.maps.LatLng(event.latLng.getLat(), event.latLng.getLng()),
            map: map
          });
          that.markersArray.push(marker);
          
          that.fristForm.longitude = event.latLng.getLng(); // 经度
          that.fristForm.latitude = event.latLng.getLat(); // 纬度
          var lat = event.latLng.getLat().toFixed(6);
          var lng = event.latLng.getLng().toFixed(6);
          // position.innerHTML = lat + "," + lng;
          that.latGet = lat + "," + lng;
          if (that.markersArray.length > 1) {
            for (let i = 0; i < that.markersArray.length - 1; i++) {
              that.markersArray[i].setMap(null);//清除标记     
            }
          }
          var gps = event.latLng.getLat() + ',' + event.latLng.getLng()
        })
        //调用地址解析类
        geocoder = new qq.maps.Geocoder({
          complete: function (result) {
            map.setCenter(result.detail.location);

            var gps = result.detail.location.lat + ',' + result.detail.location.lng
            that.latGet = gps;  //搜索拿到的经纬度
            that.fristForm = {
              latitude: result.detail.location.lat,
              longitude: result.detail.location.lng
            }
            var marker = new qq.maps.Marker({
              map: map,
              position: result.detail.location
            });
            that.markersArray.push(marker);
            if (that.markersArray.length > 1) {
              for (let i = 0; i < that.markersArray.length - 1; i++) {
                that.markersArray[i].setMap(null);//清除标记
              }
            }
          }
        })
        var searchService = new qq.maps.SearchService({
          map: map
        });
        if(item.input ==undefined || item.input ==''){
           this.saveFn()
        }else{
          let  locationList2 = item.input.split(',')
          var marker = new qq.maps.Marker({
            position: new qq.maps.LatLng(locationList2[0], locationList2[1]),
            map: map
          });
          that.markersArray.push(marker);
          that.latGet = locationList2[0] +','+locationList2[1]
        }
       
      });
      // this.saveFn(item.positionName)

    },


    //取消地图选址弹窗
    canceDown(status) {
      this.cancelStatus = status;
    }
    ,
//经纬度，地图选址确定
    cancelSave() {

      this.cancelStatus = false;
      if (this.echoToggle === false) {  //点击新增进来的
        this.formLeft.data[this.editIndex].input = this.latGet
        // if (!this.formLeft.data[0].positionName) {
        //   this.prompt()
        // } else if (this.formLeft.data[10].input && this.formLeft.data[10].positionName && this.formLeft.data[11].positionName) {
        //   this.formLeft.data[11].input = this.latGet
        //   this.nameflagFn(11)
        // } else if (this.formLeft.data[9].input && this.formLeft.data[9].positionName && this.formLeft.data[10].positionName) {
        //   this.formLeft.data[10].input = this.latGet
        //   this.nameflagFn(10)
        // } else if (this.formLeft.data[8].input && this.formLeft.data[8].positionName && this.formLeft.data[9].positionName) {
        //   this.formLeft.data[9].input = this.latGet
        //   this.nameflagFn(9)
        // } else if (this.formLeft.data[7].input && this.formLeft.data[7].positionName && this.formLeft.data[8].positionName) {
        //   this.formLeft.data[8].input = this.latGet
        //   this.nameflagFn(8)
        // } else if (this.formLeft.data[6].input && this.formLeft.data[6].positionName && this.formLeft.data[7].positionName) {
        //   this.formLeft.data[7].input = this.latGet
        //   this.nameflagFn(7)
        // } else if (this.formLeft.data[5].input && this.formLeft.data[5].positionName && this.formLeft.data[6].positionName) {
        //   this.formLeft.data[6].input = this.latGet
        //   this.nameflagFn(6)
        // } else if (this.formLeft.data[4].input && this.formLeft.data[4].positionName && this.formLeft.data[5].positionName) {
        //   this.formLeft.data[5].input = this.latGet
        //   this.nameflagFn(5)
        // } else if (this.formLeft.data[3].input && this.formLeft.data[3].positionName && this.formLeft.data[4].positionName) {
        //   this.formLeft.data[4].input = this.latGet
        //   this.nameflagFn(4)
        // } else if (this.formLeft.data[2].input && this.formLeft.data[2].positionName && this.formLeft.data[3].positionName) {
        //   this.formLeft.data[3].input = this.latGet
        //   this.nameflagFn(3)
        // } else if (this.formLeft.data[1].input && this.formLeft.data[1].positionName && this.formLeft.data[2].positionName) {
        //   this.formLeft.data[2].input = this.latGet
        //   this.nameflagFn(2)
        // } else if (this.formLeft.data[0].input && this.formLeft.data[0].positionName && this.formLeft.data[1].positionName) {
        //   this.formLeft.data[1].input = this.latGet
        //   this.nameflagFn(1)
        // } else if (this.formLeft.data[0].positionName) {
        //   this.formLeft.data[0].input = this.latGet
        //   this.nameflagFn(0)
        // } else {
        //   this.prompt()
        // }
      } else if (this.echoToggle === true) { //点击修改进来的
        if (!this.formLeft.data[0].positionName) {
          this.prompt()
        } else if (this.formLeft.data[0].positionName) {
          this.formLeft.data[0].input = this.latGet
          this.nameflagFn(0)
        }
      }
    },

    nameflagFn(index) {
      if (this.input1) {
        this.formLeft.data[index].positionName = this.input1
      }
    },

    prompt() {
      this.$Message.info({
        background: true,
        content: "请先填写地点!"
      });
    },

    getFormData() {
      return {
        type: this.type,
        ...this.formLeft
      }
    },
  }
}
</script>

<style scoped lang="less">
#info {
  position: absolute;
  left: 77px;
  top: 103px;
  font-size: 14px;
  background: #FFF;
  //width: 270px;
  padding: 10px;
  border-radius: 3px;
}

.content {
  padding: 10px 20px;

  .header-p {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .header-wrapper {
    display: flex;
    margin-bottom: 20px;

    > .tooltip {
      display: flex;
      margin-right: 200px;

      /deep/ .ivu-tooltip-rel {
        > span {
          margin-right: 5px;
        }
      }
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div:first-child {
      display: flex;
      margin-bottom: 10px;

      > span:not(:nth-child(3)):not(:nth-child(4)) {
        width: 510px;
      }

      > span:not(:nth-child(1)):not(:nth-child(2)) {
        width: 200px;
      }
      span:nth-child(1){
        width: 430px !important;
      }
      span:nth-child(3){
        padding-left: 74px !important;
      }
      span:nth-child(4){
        padding-left: 27px !important;
      }
    }

    > .form-main {
      display: flex;
      width: 100%;
      justify-content: space-between;

      > .ivu-form-item {
        margin-bottom: 10px;
      }
    }
  }
}
.location{
  display: flex;
  .location-div{
    line-height: 32px;
    margin-left: 20px;
    font-size: 16px;
    span{
      margin-left: 5px;
    }
  }
}
.modal-location{
  /deep/ #modal_contentarea{
    height: 100% !important;
  }
}
</style>
